<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-table row-key="id" :data="list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="title" label="专题名称"></el-table-column>
        <el-table-column prop="share_title" label="分享标题"></el-table-column>
        <el-table-column prop="share_desc" label="分享描述"></el-table-column>
        <el-table-column label="分享图标">
          <template #default="s">
            <img v-if="s.row.share_pic" :src="s.row.share_pic | tomedia" style="height: 3rem;width: 3rem;border-radius: .5rem" alt="">
          </template>
        </el-table-column>
        <el-table-column prop="home_show" label="首页展示" :formatter="e=>e.home_show ? '是' : '否'"></el-table-column>
        <el-table-column prop="home_show_num" label="首页展示商品数量"></el-table-column>
        <el-table-column fixed="right" width="200" align="center" label="操作">
          <template #header>
            <el-button @click="editBox=true" size="mini" type="primary">添加</el-button>
          </template>
          <template #default="s">
            <div style="margin-bottom: .5rem">
              <el-button @click="$message.info('复制成功')" v-clipboard:copy="`/pages/goods/theme?tid=${s.row.id}`">复制专题页链接</el-button>
            </div>
            <el-button @click="edit(s.row)" size="mini" type="primary">编辑</el-button>
            <el-button @click="del(s.row.id)" size="mini" type="danger">移除</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
    <el-dialog title="编辑专题" :visible.sync="editBox" @close="editBoxClose">
      <div>
        <el-form label-width="100px">
        <el-row :gutter="40">
          <el-col :span="24">

          </el-col>
          <el-col :span="12">
            <el-form-item label="专题名称">
              <el-input v-model="editForm.title"></el-input>
            </el-form-item>
            <el-form-item label="分享标题">
              <el-input v-model="editForm.share_title"></el-input>
            </el-form-item>
            <el-form-item label="分享描述">
              <el-input v-model="editForm.share_desc"></el-input>
            </el-form-item>
            <el-form-item label="分享图标">
              <y_upload_img v-model="editForm.share_pic"></y_upload_img>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="首页展示">
              <el-radio-group v-model="editForm.home_show">
                <el-radio :label="false">否</el-radio>
                <el-radio :label="true">是</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="首页展示效果">
              <el-radio-group v-model="editForm.home_column">
                <el-radio :label="0">单列</el-radio>
                <el-radio :label="1">双列</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="首页商品数量">
              <el-input-number v-model="editForm.home_show_num"></el-input-number>
            </el-form-item>
            <el-form-item label="首页头图">
              <y_upload_img v-model="editForm.home_banner"></y_upload_img>
            </el-form-item>
            <el-form-item label="首页列表背景">
              <y_upload_img v-model="editForm.home_bg"></y_upload_img>
            </el-form-item>
          </el-col>
        </el-row>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editBox = false">取 消</el-button>
        <el-button type="primary" @click="editSubmit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
export default {
  components:{y_upload_img},
  name: "list",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      size: 15,
      editBox:false,
      editForm:{
        title:"",
        share_title:"",
        share_desc:"",
        share_pic:"",

        home_banner:"",
        home_bg:"",
        home_show:false,
        home_column:0,
        home_show_num:0,
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    edit(item){
      this.editForm = {...item};
      this.editBox = true;
    },
    editSubmit(){
      this.$u.api.shop.goods.themeEdit(this.editForm).then(()=>{
        this.$message.success("操作成功");
        this.load();
        this.editBox = false;
      })
    },
    editBoxClose(){
      this.editForm = {
        title:"",
        share_title:"",
        share_desc:"",
        share_pic:"",

        home_banner:"",
        home_bg:"",
        home_show:false,
        home_column:0,
      };
      this.editBox = false;
    },
    del(id) {
      this.$u.api.shop.goods.themeDel({id}).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },

    load() {
      this.$u.api.shop.goods.themeSearch({page: this.page}).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(e) {
      this.page = e;
      this.load();
    }
  }
}
</script>

<style scoped>

</style>